import { useContext, useEffect } from 'react';

import { ctxNotification } from '@/contexts/NotificationCtx';
import { useTranslation } from 'react-i18next';

export default function VersionUpdate() {
  const lastKnownVersion = localStorage.getItem('lastKnownVersion') || null;
  const { t } = useTranslation();
  const ctxNotify = useContext(ctxNotification);

  useEffect(() => {
    if (lastKnownVersion !== VERSION) {
      ctxNotify?.showNotification('success', t('error.dialog.newVersionUpdate'));
      localStorage.setItem('lastKnownVersion', VERSION);
    }
  }, []);

  return <></>;
}
