import { lazy, useCallback, useState, useEffect } from 'react';

import type { Home, LoginPage } from '@/components';
import { PleaseWait } from '@/components';
import { TokenFail } from './components/common/TokenFail';
import { UserState } from '@/types';
import { useUserState } from '@/hooks/useUserState';
import { ctxUser } from '@/contexts/UserContext';
import { User } from '@/tmp';
import { HandleErrors } from './HandleErrors';
import { checkCookiebot } from '@/utilities/checkCookiebot';

const HomeLazy = lazy(() => import('@/components/home/Home')) as typeof Home;
const LoginPageLazy = lazy(() => import('@/components/login/LoginPage')) as typeof LoginPage;

type FlutterMessage = {
  postMessage: (message: string) => void;
};
declare global {
  // eslint-disable-next-line no-var
  var Logout: FlutterMessage, continueTracking: () => void, allowNotifications: (allowed: boolean) => void;
  // eslint-disable-next-line no-var
  var userConsent: FlutterMessage, updateUserConsent: (allowed: string) => void;
}

function App() {
  const [user, setUser] = useUserState(undefined);
  const [trackingAllowed, setTrackingAllowed] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (!window.userConsent || !checkCookiebot()) {
      return;
    }

    window.updateUserConsent = function (allowed) {
      let allowedByUser: boolean;
      if (allowed === 'true') {
        allowedByUser = true;
      } else {
        allowedByUser = false;
      }
      setTrackingAllowed(allowedByUser);
    };

    function onCookiebotDialog() {
      if (!window.userConsent) {
        return;
      }
      if (trackingAllowed) {
        return;
      }
      Cookiebot.hide();
    }

    window.addEventListener('CookiebotOnDialogDisplay', onCookiebotDialog);

    if (trackingAllowed === undefined) {
      window.userConsent.postMessage('');
    } else {
      try {
        if (trackingAllowed) {
          if (!Cookiebot.hasResponse) {
            Cookiebot.show();
          }
        } else {
          if (Cookiebot.hasResponse) {
            Cookiebot.withdraw();
          } else {
            Cookiebot.hide();
          }
        }
      } catch (e) {
        console.error('Cannot update Cookiebot. TrackingAllowed = ', trackingAllowed, e);
      }
    }
    return () => {
      window.removeEventListener('CookiebotOnDialogDisplay', onCookiebotDialog);
    };
  }, [trackingAllowed]);

  const logout = useCallback(async () => {
    await setUser(null);
    // TODO cleanup localStorage properly
    localStorage.removeItem('userMessageTimeMillis');
    if (window.Logout) {
      window.Logout.postMessage('Logout clicked');
    }
    // eslint-disable-next-line
    window.continueTracking = function () {};
  }, [setUser]);

  function loggedIn(user: UserState) {
    setUser(user);
  }

  function RenderLogin() {
    return (
      <HandleErrors logout={() => {}}>
        <LoginPageLazy loggedIn={loggedIn} />
      </HandleErrors>
    );
  }

  function RenderApp() {
    return (
      <ctxUser.Provider
        value={{
          user: user as User,
          isToken: () => (user as User)?.isTokenUser,
          hasExtraFeature: (extraFeature: string) => (user as User)?.extraFeatures?.includes(extraFeature),
        }}
      >
        <HandleErrors logout={logout}>
          <HomeLazy logout={logout} />
        </HandleErrors>
      </ctxUser.Provider>
    );
  }

  switch (user) {
    case undefined:
      return <PleaseWait />;
    case null:
      return <RenderLogin />;
    case 'token_failure':
      return <TokenFail />;
    default:
      return <RenderApp />;
  }
}

export default App;
