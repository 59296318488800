import { PlatformInformation } from '@/types';
import { IResult, UAParser } from 'ua-parser-js';

type FlutterMessage = {
  postMessage: (message: string) => void;
};

declare global {
  // eslint-disable-next-line no-var
  var platformInformation: FlutterMessage;
}

function getParsedUserAgent(): IResult {
  return UAParser(navigator.userAgent);
}

async function requestPlatformInformation() {
  return new Promise<PlatformInformation>((resolve, reject) => {
    if (!window.platformInformation) {
      return reject();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleResponse(this: Window, event: MessageEvent<any>) {
      window.removeEventListener('message', handleResponse);
      // neha rossz valasz jon, pl az elmentett jelszo...
      // FIXME ha ezt a dolgot a flutter kitoltene defaultbol akkor nem kellene ez a kommunikacio
      if (event.data.length < 2 || event.data[0] !== 'platformInformation') {
        return reject(new Error('wrong answer'));
      }
      resolve(event.data[1]);
    }

    window.addEventListener('message', handleResponse);
    setTimeout(() => reject(new Error('timeout')), 500);
    window.platformInformation.postMessage('');
  });
}

/*
function string2hex(tmp: string) {
  if (!tmp) return '?';
  let str = '';
  for(let i = 0; i < tmp.length; i++) {
      str += tmp[i].charCodeAt(0).toString(16);
  }
  return str;
}

function ascii(v: string) {
  const regex = /^[\u0020-\u007e]*$/;
  // checks the string to contain base64 characters only
  // suitable for http header values
  if (!regex.test(v)) {
    console.error('non base64 value found: ', string2hex(v));
    return '';
  }
  return v;
}
*/

function base64(v: string) {
  const regex = /^[\u0020-\u007e]*$/;
  if (regex.test(v)) return v;

  const uint8Array = new TextEncoder().encode(v);
  // Uint8Array.prototype.toBase64() is not available
  const binString = String.fromCodePoint(...uint8Array);
  return 'data:text/plain;base64,' + btoa(binString);
}

export async function getRequestConfigurationWithDeviceInfos() {
  const appVersionHeader: string[] = [];
  const viewportHeader: string[] = [];
  const platformHeader: string[] = [];
  const deviceModelHeader: string[] = [];
  const browserVersionHeader: string[] = [];

  try {
    const platformInformation = await requestPlatformInformation();

    const appVersion = `"${platformInformation.app.name}";v="${platformInformation.app.version}";p="${platformInformation.app.package}";b="${platformInformation.app.buildNumber}";src=App`;
    appVersionHeader.push(base64(appVersion));

    const appDisplayScreen = `"Screen";w=${platformInformation.display.screen.width};h=${platformInformation.display.screen.height};src=App`;
    viewportHeader.push(base64(appDisplayScreen));
    const appDisplayViewport = `"Viewport";w=${platformInformation.display.viewport.width};h=${platformInformation.display.viewport.height};src=App`;
    viewportHeader.push(base64(appDisplayViewport));
    const appDisplaySafePadding = `"SafePadding";t=${platformInformation.display.safePadding.top};b=${platformInformation.display.safePadding.bottom};l=${platformInformation.display.safePadding.left};r=${platformInformation.display.safePadding.right};src=App`;
    viewportHeader.push(base64(appDisplaySafePadding));

    const appOSVersion = `"${platformInformation.os.name}";v="${platformInformation.os.version}";src=App`;
    platformHeader.push(base64(appOSVersion));
    const appOSOther = `c="${platformInformation.os.codeName}";b="${platformInformation.os.buildId}";sdk="${platformInformation.os.sdk}";src=App`;
    platformHeader.push(base64(appOSOther));

    const appDeviceModel = `"${platformInformation.model.name}";b="${platformInformation.model.brand}";c="${platformInformation.model.codeName}";p="${platformInformation.model.isPhysical}";src=App`;
    deviceModelHeader.push(base64(appDeviceModel));
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (_error) {
    // We dont have platform information
    // console.debug('🦾', _error);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((navigator as any).userAgentData) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const extendedUserAgent = await (navigator as any).userAgentData.getHighEntropyValues([
      'fullVersionList',
      'model',
      'platformVersion',
    ]);
    platformHeader.push(
      base64(`"${extendedUserAgent.platform}";v="${extendedUserAgent.platformVersion ?? ''}";src=UAD`)
    );
    if (extendedUserAgent.model) {
      deviceModelHeader.push(base64(`"${extendedUserAgent.model}";src=UAD`));
    }
    if (extendedUserAgent.fullVersionList) {
      browserVersionHeader.push(
        base64(
          extendedUserAgent.fullVersionList.map(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (entry: any) => `"${entry.brand}";v="${entry.version}";src=UAD`
          )
        )
      );
    } else {
      browserVersionHeader.push(
        base64(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          extendedUserAgent.brands.map((entry: any) => `"${entry.brand}";v="${entry.version}";src=UAD`)
        )
      );
    }
  }

  const parsedUserAgent = getParsedUserAgent();

  const uapBrowser = parsedUserAgent.browser;
  if (uapBrowser.name) {
    const uapBrowserVersion = `"${uapBrowser.name}";v="${uapBrowser.version ?? ''}";src=UAP`;
    browserVersionHeader.push(base64(uapBrowserVersion));
  }

  const uapEngine = parsedUserAgent.engine;
  if (uapEngine.name) {
    const uapEngineVersion = `"${uapEngine.name}";v="${uapEngine.version ?? ''}";src=UAP`;
    browserVersionHeader.push(base64(uapEngineVersion));
  }

  const uapDevice = parsedUserAgent.device;
  if (uapDevice.model) {
    const uapDeviceModel = `"${uapDevice.model}";b="${uapDevice.vendor ?? ''}";t="${uapDevice.type ?? ''}";src=UAP`;
    deviceModelHeader.push(base64(uapDeviceModel));
  }

  const uapOS = parsedUserAgent.os;
  if (uapOS.name) {
    const uapOSVersion = `"${uapOS.name}";v="${uapOS.version ?? ''}";src=UAP`;
    platformHeader.push(base64(uapOSVersion));
  }

  const uapCPU = parsedUserAgent.cpu;
  if (uapCPU.architecture) {
    const uapArchitecture = `cpu="${uapCPU.architecture}";src=UAP`;
    platformHeader.push(base64(uapArchitecture));
  }

  viewportHeader.push(`"Screen";w=${window.screen.width};h=${window.screen.height};src=Window`);
  const { innerWidth: width, innerHeight: height } = window;
  if (width > 0 || height > 0) {
    viewportHeader.push(`"Viewport";w=${width};h=${height};src=Window`);
  } else {
    viewportHeader.push(`"Viewport";w=${window.screen.availWidth};h=${window.screen.availHeight};src=Window`);
  }

  return {
    headers: {
      'UDS-App-Version': appVersionHeader.length ? appVersionHeader.join(', ') : undefined,
      'UDS-Viewport': viewportHeader.length ? viewportHeader.join(', ') : undefined,
      'UDS-OS-Platform': platformHeader.length ? platformHeader.join(', ') : undefined,
      'UDS-Device-Model': deviceModelHeader.length ? deviceModelHeader.join(', ') : undefined,
      'UDS-Browser-Version': browserVersionHeader.length ? browserVersionHeader.join(', ') : undefined,
    },
  };
}
